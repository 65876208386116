<template>
    <div>
        <h1>Mr. Ynk Frontnd CLI</h1>
        <p>Type: {{ appType }}</p>
        <v-mic></v-mic>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Mic from '@/app/components/Mic';

    export default {
        components: {
            'v-mic': Mic
        },

        data()
        {
            return {
                appType: 'clean'
            };
        }
    };
</script>
