<template>
    <div>
        <div class="controls">
            <button :class="{ on: recording, record: true }" @click="toggle( 'recording' )">&bull;</button>
            <button @click="stopAll">STOP</button>
            <!-- <button :class="{ on: playback }"  @click="toggle( 'playback' )">Playback</button>
            <button>Pause</button> -->
        </div>

        <div class="visualiser">
            <canvas ref="oscilloscope"></canvas>
        </div>

        <div class="sample-wrapper">
            <div class="sample" v-for="(sample, key) in audioMap" @click="play( sample )">
                {{ key }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .controls{
        margin-bottom:1rem;
        display:flex;
        justify-content:center;
        align-items:stretch;
    }

    button{
        padding:1rem 2rem;

        &.record{
            color: red;
            font-size:3rem;
            padding: 0rem 2rem;
        }
    }

    button.on{
        background-color: grey;
        border:1px solid black;
        border-radius:2px;
        // padding-top: calc( 1rem + 1px );
        // padding-bottom: calc( 1rem + 1px );
    }

    .sample-wrapper{
        display:flex;
        justify-content:center;
        margin: 1rem auto;

        .sample{
            display:inline-block;
            width:150px;
            height:150px;
            margin: 0 1rem;

            border:1px solid black;

            line-height:150px;

            cursor:pointer;
        }
    }
</style>

<script>
    export default {
        data()
        {
            return {
                recording: false,
                playback: false,

                audioMap: []
            };
        },

        mounted()
        {
            this.mediaRecorder = null;
            // init

            /*
                order:
                    - check/handle permission
                    - start getUserMedia from microphone
                    - while getting data (stream):
                        - save data chunks
                        - create oscilloscope based on chunk data

                    - onStop: combine all chunks into audio blob
                    - save blob(url) in audioMap
             */
        },

        methods: {
            togglePlayback()
            {
                if( this.playback )
                {
                    console.log( 'starting playback' );
                    this.recording = false;
                }
            },

            async checkPermission()
            {
                return new Promise( ( resolve, reject ) =>
                {
                    navigator.permissions.query({ name: 'microphone' }).then( result =>
                    {
                        if( result.state === 'denied' )
                        {
                            console.log( 'no mic permission' );
                            reject( new Error( 'no microphone permission' ) );
                        }

                        result.onchange = () =>
                        {
                            if( result.state === 'denied' )
                            {
                                this.recording = false;
                            }
                        };

                        resolve( result.state );
                    });
                });
            },

            async startRecording()
            {
                console.log( 'starting recording' );

                await this.checkPermission()
                    .then( () =>
                    {
                        navigator.mediaDevices.getUserMedia({ audio: { channels: 2, autoGainControl: false, echoCancellation: false, noiseSuppression: false } })
                            .then( stream =>
                            {
                                // actually start recording
                                this.mediaRecorder = new MediaRecorder( stream );
                                this.mediaRecorder.start();

                                const audioChunks = [];

                                // visualise the stream
                                this.makeOscilloscope( stream );

                                // while getting data, push to audioChunks array
                                this.mediaRecorder.addEventListener( 'dataavailable', event =>
                                {
                                    audioChunks.push( event.data );
                                });

                                this.mediaRecorder.addEventListener( 'stop', () =>
                                {
                                    let audioBlob = new Blob( audioChunks );
                                    let audioUrl = URL.createObjectURL( audioBlob );

                                    // stopping stream so tab doesn't show it's still recording
                                    stream.getTracks().forEach( track => track.stop() );

                                    this.audioMap.push({ url: audioUrl });

                                    //save audioMap change to store or backend, or save single audio sample?
                                });
                            });
                    })
                    .catch( () =>
                    {
                        this.stopRecording();
                    });
            },

            makeOscilloscope( stream )
            {
                this.audioCtx = new( window.AudioContext || window.webkitAudioContext )();

                if( !this.audioCtx )
                {
                    console.log( 'audioCtx not found, returning' );
                    return;
                }

                // make analyser and connect
                // https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode

                this.analyser = this.audioCtx.createAnalyser();
                this.analyser.fftSize = 2048;

                this.bufferLength = this.analyser.frequencyBinCount;
                this.dataArray = new Uint8Array( this.bufferLength );
                this.analyser.getByteTimeDomainData( this.dataArray );

                this.source = this.audioCtx.createMediaStreamSource( stream );
                this.source.connect( this.analyser );

                // make canvas to draw on

                this.canvas = this.$refs.oscilloscope;
                this.canvasCtx = this.canvas.getContext( '2d' );

                this.drawOscilloscope();
            },

            drawOscilloscope()
            {
                if( !this.recording )
                {
                    return;
                }

                requestAnimationFrame( this.drawOscilloscope );

                // bind data flow from analyser to this.dataArray
                this.analyser.getByteTimeDomainData( this.dataArray );

                this.canvasCtx.fillStyle = 'rgb( 200, 200, 200 )';
                this.canvasCtx.fillRect( 0, 0, this.canvas.width, this.canvas.height );

                this.canvasCtx.lineWidth = 2;
                this.canvasCtx.strokeStyle = 'rgb( 0, 0, 0 )';

                this.canvasCtx.beginPath();

                const sliceWidth = this.canvas.width * 1 / this.bufferLength;

                let x = 0;

                for( let i = 0; i < this.bufferLength; i++ )
                {
                    const v = this.dataArray[i] / 128;
                    const y = v * this.canvas.height / 2;

                    if( i === 0 )
                    {
                        this.canvasCtx.moveTo( x, y );
                    }
                    else
                    {
                        this.canvasCtx.lineTo( x, y );
                    }

                    x += sliceWidth;
                }

                this.canvasCtx.lineTo( this.canvas.width, this.canvas.height / 2 );
                this.canvasCtx.stroke();
            },

            stopRecording()
            {
                console.log( 'stopping recording' );
                this.recording = false;

                if( this.mediaRecorder )
                {
                    this.mediaRecorder.stop();
                }
            },

            stopAll()
            {
                if( this.mediaRecorder && this.mediaRecorder.state !== 'inactive' )
                {
                    this.mediaRecorder.stop();
                }

                this.recording = false;
                this.playback = false;

                this.audioMap.forEach( sample =>
                {
                    sample.elem.pause(); sample.elem.currentTime = 0;
                });
            },

            toggle( pWhich )
            {
                this[ pWhich ] = !this[ pWhich ];

                switch( pWhich )
                {
                    case 'recording':
                        if( this.recording )
                        {
                            this.startRecording();
                        }
                        else
                        {
                            this.stopRecording();
                        }
                        break;
                    case 'playback':
                        this.togglePlayback();
                        break;
                }
            },

            play( sample )
            {
                console.log( 'sample', sample );

                if( !sample.elem )
                {
                    // save audio elem on sample for reuse
                    const audio = new Audio( sample.url );
                    sample.elem = audio;
                }

                sample.elem.play();
            }
        }
    };
</script>
